* {
  font-family: MontserratWeb, regular, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #989898;
  border-radius: 4px;
}
#root {
  display: flex;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  overflow-x: scroll;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-layout {
  min-width: 1200px !important;
}

.ant-modal .ant-modal-content {
  border-radius: 15px;
}
.ant-modal .ant-modal-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  border-radius: 14px;
}

.ant-select-dropdown {
  border-radius: 15px !important;
}

.ant-dropdown-menu {
  border-radius: 15px !important;
}

.ant-btn {
  border-radius: 20px !important;
}
.ant-btn.ant-btn-primary {
  background-color: #0073ae !important;
  border: 1px #0073ae solid !important;
}
.ant-btn.ant-btn-primary:hover {
  background-color: #124885 !important;
  border: 1px #124885 solid !important;
}
.ant-btn.ant-btn-default:hover {
  border: 1px #0073ae solid;
  color: #0073ae;
}
.ant-modal-confirm-title {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-bottom: 10px;
}